
class AbsorbantRoom {

    constructor(id, empty=true, name="") {
        this.id = id
        this.empty = empty
        this.name = name
        this.absorbantWalls = []
        this.absorbantWalls.push(new AbsorbantWall("1", "front"))
        this.absorbantWalls.push(new AbsorbantWall("2", "right"))
        this.absorbantWalls.push(new AbsorbantWall("3", "back"))
        this.absorbantWalls.push(new AbsorbantWall("4", "left"))
        this.activeAbsorbantWallIndex = 0
    }
}

export default AbsorbantRoom

class AbsorbantWall {
    
    constructor(name, description) {
        this.name = name
        this.description = description
        this.layerOne = new AbsorbantLayer()
        this.layerTwo = new AbsorbantLayer()
    }
   
}

class AbsorbantLayer {
    
    constructor(
        productIndex = -1,
        width = 0,
        height = 0,
        folded = true,
        distance = 15,
        product = null,
        colorIndex = 0,
        readyToSave = false) {
            this.productIndex = productIndex
            this.width = width
            this.height = height
            this.folded = folded
            this.distance = distance
            this.product = product
            this.colorIndex = colorIndex
            this.readyToSave = readyToSave
    }

}
export {AbsorbantLayer}


