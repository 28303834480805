import React from 'react'
import intl from 'react-intl-universal'
import Modal from 'react-bootstrap/Modal'
import styles from './LabNav.module.scss'
import Button from './Button'
import iconPlus from './icon-plus.svg'
import iconReset from './icon-reset.svg'
import iconSave from './icon-save.svg'
import iconPdf from './icon-pdf.svg'
import iconClose from '../Lab/icon-close.svg'
import LightPDFGenerator from '../Reports/PDF/LightPDFGenerator'

class LabNav extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            didMount: false,
            showModalReset: false
        }
        this.currentCache = null
        this.LightPDFGenerator = new LightPDFGenerator()  
    }

    generatePdf() {
        
        this.LightPDFGenerator.setParams(
            this.props.absorbantRooms, 
            this.props.roomWidth,
            this.props.roomHeight,
            this.props.roomDepth,
            this.props.ceilingMaterial,
            this.props.wallMaterial,
            this.props.floorMaterial
        )
        this.LightPDFGenerator.start()
    }

    onShowModalReset() {
        this.setState({
            showModalReset: true
        })
    }

    onCloseModalReset() {
        this.setState({
            showModalReset: false
        })
    }
    onSaveAs() {
        this.onCloseModalReset()
        this.props.onViewChanged(this.props.views.saveReport)
    }
    handleReset() {
        if (localStorage.getItem('currentCache')) {
            if (this.props.hasBeenSaved) {
                this.resetToDefaults()
            }
            else {
                this.onShowModalReset()
            }
        }
    }
    resetToDefaults() {
        
        this.onCloseModalReset()
        this.props.resetToDefaults()
        this.props.onViewChanged(this.props.views.labStart)
    }

    componentDidMount() {
        this.setState({
            didMount: true
        })

        if (localStorage.getItem('currentCache')) {
            this.currentCache = JSON.parse(localStorage.getItem('currentCache'))
         }
         
    }


    componentDidUpdate() {
        
    }

    componentWillUnmount() {

    }
    

    render () {
        const storedUser = JSON.parse(sessionStorage.getItem("currentUser")); // Get user from sessionStorage
        const { salesAcoustic } = storedUser; // Gets salesAcoustic from storedUser

        return (
            <>

                <div className={styles.container} ref={(ref) => this.containerRef = ref}>
                    
                    <div className={styles.leftsideButtons}>
                        {
                            this.props.absorbantRooms.map((room, index) => {
                                let label = null
                                let icon = null
                                if (room.empty) {
                                    label = intl.get('labnav.roomButtonEmpty')
                                }
                                else if (index < this.props.absorbantRooms.length-1
                                    || this.props.atLeastOneProductActive(index)
                                    || index === this.props.activeAbsorbantRoomIndex) { 
                                        label = index 
                                }
                                else if (salesAcoustic) { // Only add button if salesAcoustic is true
                                    icon = <img src={iconPlus} alt="icon" />;
                                    label = "";  // No label needed when salesAcoustic is true
                                }
                                let buttonActive = false
                                if (index === this.props.leftmostAbsorbantRoomIndex) {
                                    buttonActive = true
                                }
                                if ( !this.props.inputpaneActive 
                                    && !this.props.fullWidthActive
                                    && index === this.props.leftmostAbsorbantRoomIndex+1 ) {
                                    buttonActive = true
                                }

                                let moveToIndex = index
                                if (
                                    !this.props.inputpaneActive
                                    && !this.props.fullWidthActive) { 
                                    if (index === this.props.absorbantRooms.length -1 ) {
                                        if (this.props.absorbantRooms.length >= 2) {
                                            --moveToIndex
                                        } 
                                    }
                                }
                                return label !== null && ( // Only add button if salesAcoustic is true and label is not null
                                <Button
                                    active={buttonActive}
                                    square={true}
                                    borders={true}
                                    label={label}
                                    icon={icon}
                                    onClick={() => { if (!salesAcoustic) {
                                        this.props.setLeftmostAbsorbantRoom(0);
                                    } else {
                                        this.props.setLeftmostAbsorbantRoom(moveToIndex);
                                    } }}
                                    key={index}
                                    disabled={this.props.currentLabView === this.props.labViews.report}
                                />
                            );
                            })
                        }
                    </div>
                    <div className={styles.rightsideButtons}>
                        <Button square={true} borders={false} icon={<img src={iconReset} alt="icon" onClick={() => this.handleReset() } />} />
                        <Button square={true} borders={false} icon={<img src={iconSave} alt="icon" />} onClick={() => this.props.onViewChanged(this.props.views.saveReport)} />
                        <Button square={true} borders={false} icon={<img src={iconPdf} alt="icon" />} onClick={() => this.generatePdf()} />
                        {
                            salesAcoustic && (
                                <Button
                                    rounded={true}
                                    strongBorders={true}
                                    label={intl.get('report.report')}
                                    onClick={this.props.toggleReportView}
                                    active={this.props.currentLabView === this.props.labViews.report}
                                />
                            )
                        }

                    </div>
                    
                    
                </div>

                <Modal show={this.state.showModalReset} onHide={() => this.onCloseModalReset()}>
                    <Modal.Header className={styles.modalHeader}>
                        <Modal.Body>
                        <h3>{intl.get('session.newHeader')}</h3>
                        <p>{intl.get('session.newDescription')}</p>
                        </Modal.Body>
                        <Button 
                            className={styles.btnClose}
                            square
                            icon={<img src={iconClose} alt="icon" />} 
                            onClick={() => this.onCloseModalReset()}
                        />
                    </Modal.Header>
                    <Modal.Footer>
                    <Button 
                            label={intl.get('general.dontSave')} 
                            onClick={() => this.resetToDefaults()} 
                        />
                        <Button 
                            label={intl.get('general.cancel')} 
                            borders
                            rounded
                            onClick={() => this.onCloseModalReset()} 
                        />
                        <Button 
                            label={intl.get('general.saveAs')} 
                            className={styles.btnCancel} 
                            rounded
                            strongBorders
                            onClick={() => this.onSaveAs()} 
                        />
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default LabNav