import React from 'react'
import intl from 'react-intl-universal'
import styles from './ButtonPlay.module.scss'
import iconPlayLarge from './icon-play-large.svg'
import iconPauseLarge from './icon-pause-large.svg'
import iconPlaySmall from './icon-play.svg'
import iconPauseSmall from './icon-pause.svg'

class ButtonPlay extends React.Component {
    render() {

        let iconPlay = iconPlayLarge
        let iconPause = iconPauseLarge
        if (this.props.compact) {
            iconPlay = iconPlaySmall
            iconPause = iconPauseSmall
        }

        const imgPlay = <img src={iconPlay} alt="Play" />
        const imgPause = <img src={iconPause} alt="Pause" />

        return (
            <button 
                onClick={() => {
                    if (this.props.onClick) {
                        this.props.onClick(this.props.value)
                    }
                }}
                type="button" 
                value={this.props.value}
                className={`${styles.container} ${this.props.compact && styles.compact}`}
            >
                {this.props.paused ? imgPlay : imgPause}
                {!this.props.compact &&
                    <span>{intl.get('audio.playSound')}</span>
                }
            </button> 
        )
    }
}

export default ButtonPlay
