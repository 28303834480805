import React, {Component} from 'react'
import intl from 'react-intl-universal'
import Slider from './Slider'
import styles from './RoomVolume.module.scss'

class RoomVolume extends Component {
    
    render() {
        
        return (
            <div className={styles.container}>
                <h3>{intl.get('topNav.volume')}</h3>
                <div className={styles.controllerContainer}>
                    <Slider 
                        label={intl.get('general.width')}
                        min={1.00}
                        max={50.00}
                        step={0.01}
                        value={this.props.roomWidth}
                        onSliderChange={this.props.onRoomWidthChanged}
                        onSliderTouchStart={this.props.disableScroll}
                        onSliderTouchEnd={this.props.enableScroll}
                        inputMode={"decimal"}
                    />
                </div>
                <div className={styles.controllerContainer}>
                    <Slider 
                        label={intl.get('general.depth')}
                        min={1.00}
                        max={50.00}
                        step={0.01}
                        value={this.props.roomDepth}
                        onSliderChange={this.props.onRoomDepthChanged}
                        onSliderTouchStart={this.props.disableScroll}
                        onSliderTouchEnd={this.props.enableScroll}
                        inputMode={"decimal"}
                    />
                </div>
                <div className={styles.controllerContainer}>
                    <Slider 
                        label={intl.get('general.height')}
                        min={1.00}
                        max={10.00}
                        step={0.01}
                        value={this.props.roomHeight}
                        onSliderChange={this.props.onRoomHeightChanged}
                        onSliderTouchStart={this.props.disableScroll}
                        onSliderTouchEnd={this.props.enableScroll}
                        inputMode={"decimal"}
                    />
                </div>
            </div>
        )
    }
}

export default RoomVolume