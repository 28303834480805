import React, {Component} from 'react'
import helpers from '../../../utils/helpers'
import ButtonPlay from './ButtonPlay'
import styles from './Audio.module.scss'
import gainBackground from './gain-background.png'
import audioFile2 from './435381__pievarino__conference-lunch_01.mp3'
import products from '../../../data/products'

class Audio extends Component {

    constructor(props) {
        super(props)

        this.state = {
            didMount: false,
            simulateVolume: true,
            isPaused: true
        }

        this.togglePlay = this.togglePlay.bind(this)
        this.play = this.play.bind(this)
        this.pause = this.pause.bind(this)
    }

    togglePlay() {
        if(this.state.isPaused) {
            this.play()
        }
        else {
            this.pause()
        }
    }

    play() {
        if (this.audioContext.state === 'suspended') {
            this.audioContext.resume()
        }
        this.player.play()
        this.setState({
            isPaused: false
        })
        this.props.startedPlaying(this.props.index)
    }

    pause() {
        this.player.pause()
        this.setState({
            isPaused: true
        })
    }


    dBFSToGain(dbfs) {
        return helpers.dBReductionToGain(-dbfs)
    }


    setupAudioContext() {
            
        this.audioContext = new (window.webkitAudioContext || window.AudioContext)()
        this.audioSrc = this.audioContext.createMediaElementSource(this.player)
        this.audioGainNode = this.audioContext.createGain()
        this.audioSrc.connect(this.audioGainNode)
        this.audioGainNode.connect(this.audioContext.destination)

    }
 

    audioDraw() {
        var drawVisual = requestAnimationFrame(this.audioDraw)
        this.analyzer.getByteTimeDomainData(this.dataArray)
        this.audioCanvasContext.fillStyle = 'rgb(200, 200, 200)'
        this.audioCanvasContext.fillRect(0, 0, this.audioCanvas.width, this.audioCanvas.height)
        this.audioCanvasContext.lineWidth = 2
        this.audioCanvas.strokeStyle = 'rgb(0, 0, 0)'
        
        var sliceWidth = this.audioCanvas.width * 1.0 / this.bufferLength
        var x = 0

        this.audioCanvasContext.beginPath()
        for (var i=0; i<this.bufferLength; i++) {
            var v = this.dataArray[i] / 128.0
            var y = v * this.audioCanvas.height/2

            if (i===0) {
                this.audioCanvasContext.moveTo(x, y)
            }
            else {
                this.audioCanvasContext.lineTo(x, y)
            }

            x += sliceWidth
        }

        this.audioCanvasContext.stroke()
    }

    audioDraw2() {
        var drawVisual = requestAnimationFrame(this.audioDraw2)
        this.analyzer.getByteFrequencyData(this.dataArray)
        this.audioCanvasContext.fillStyle = 'rgb(76, 76, 76)'
        this.audioCanvasContext.fillRect(0, 0, this.audioCanvas.width, this.audioCanvas.height)
        
        
        var barWidth = (this.audioCanvas.width / this.bufferLength) * 2.5;
        var barHeight;
        var x = 0;

        for(var i = 0; i < this.bufferLength; i++) {
          barHeight = this.dataArray[i];
          this.audioCanvasContext.fillStyle = 'rgb(180, 180, 180)';
          this.audioCanvasContext.fillRect(x, this.audioCanvas.height-barHeight/2, barWidth, barHeight/2);

          x += barWidth + 1;
        }
    }

    componentDidMount() {
        
        this.setupTimer = setTimeout(() => {
            this.player.loop = true
            this.setupAudioContext()
            this.setState({
                didMount: true
            })
        }, 400)
    }


    componentDidUpdate(prevProps) {
        
        if(this.props.dBReduction !== products.dBReduction) {

            if (this.state.didMount) {
                if (this.state.simulateVolume && this.audioGainNode.gain) {
                    this.audioGainNode.gain.value = this.dBFSToGain(-this.props.dBReduction)
                }
                else {
                    this.audioGainNode.gain.value = 1.0
                }
            }
        }

        if(this.props.shouldPause !== prevProps.shouldPause) {
            if(this.props.shouldPause) {
                this.pause()
            }
        }

       
    }

    componentWillUnmount() {
        clearTimeout(this.setupTimer)
        if (this.audioContext) {
            this.audioContext.close()
        }
    }

    render() { 
        
        const dBReduction = Number.parseFloat(this.props.dBReduction).toFixed(3)

        const gainPercentage = this.dBFSToGain(-this.props.dBReduction) * 100

        return (
            
            <div className={`
                ${styles.container} 
                ${this.state.didMount && styles.mounted}
                ${this.props.compact && styles.compact}
                `}
                style={{backgroundSize: "100% 100%" }}
                >
                
                {this.props.compact &&
                    <img 
                        className={styles.background} 
                        style={{height: gainPercentage+"%", top: (100-gainPercentage)/2+"%"}}
                        src={gainBackground} alt="" />    
                }

                <audio preload="true" ref={ref => (this.player = ref)}>
                    <source src={audioFile2} type="audio/mp3"></source>
                </audio>

                <ButtonPlay 
                    paused={this.state.isPaused} 
                    onClick={this.togglePlay} 
                    compact ={this.props.compact}
                    />
                            
            </div>
        )

    }

}

export default Audio