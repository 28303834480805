import intl from 'react-intl-universal'

function atLeastOneProductActiveForRoom(absorbantRoom) {
    let atLeastOneProductActive = false
    absorbantRoom.absorbantWalls.forEach(wall => {
        if (
            (wall.layerOne.productIndex >= 0 && wall.layerOne.width > 0 && wall.layerOne.height > 0)
            || (wall.layerTwo.productIndex >= 0 && wall.layerTwo.width > 0 && wall.layerTwo.height > 0)
        ) {
            atLeastOneProductActive = true
        }
    })
    
    return atLeastOneProductActive
}

function getRoomName(absorbantRoom) {
    let name = intl.get('report.emptyRoom')
    if (!absorbantRoom.empty) {
        name = absorbantRoom.name
        if (!name || name.length === 0) {
            name = intl.get('report.interior') + " " + absorbantRoom.id
        }
    }
    return name
}

function surfaceAreaForRoom(roomWidth, roomHeight, roomDepth) {
    return 2 * roomWidth * roomHeight + 2 * roomDepth * roomHeight + 2 * roomWidth * roomDepth
}

function absorptionAreaForEmptyRoom(ceilingMaterial, wallMaterial, floorMaterial, roomWidth, roomHeight, roomDepth) {
    
    let absorptionArea = 0
    
    absorptionArea += roomWidth * roomDepth * ceilingMaterial.absorptionCoefficient
    
    const wallArea = 2 * roomWidth * roomHeight + 2 * roomDepth * roomHeight
    absorptionArea += wallArea * wallMaterial.absorptionCoefficient

    absorptionArea += roomWidth * roomDepth * floorMaterial.absorptionCoefficient

    return absorptionArea
}


function absorptionAreaForLayer(layer) {
    if (layer.productIndex >= 0) {
        const layerArea = layer.width * layer.height
        const productVariant = productVariantForLayer(layer)
        if (productVariant) {
            return layerArea * productVariant.absorptionCoefficient
        }
    }
    return false
}


function absorptionAreaForTextiles(absorbantWalls) {

    // let productNames = []
    // for (const product in products) {
    //     productNames.push(product)
    // }
    
    let absorptionArea = 0

    for (let index in absorbantWalls) {
        const absorbantWall = absorbantWalls[index]
        const layers = [absorbantWall.layerOne, absorbantWall.layerTwo]
        for (let layerIndex in layers) {
            const layer = layers[layerIndex]
            if (layer.productIndex >= 0) {
                const layerArea = layer.width * layer.height
                const productVariant = productVariantForLayer(layer)
                absorptionArea += layerArea * productVariant.absorptionCoefficient
            }
        }
    }

    return absorptionArea
}

function productVariantForLayer(layer) {
    let absorptionCoefficient = null
    let absorptionClass = null
    let productVariant = null
    if (!layer.product) {
        return null
    }
    if (layer.productIndex >= 0) {
        if (!layer.folded && layer.distance === "10") {
            productVariant = layer.product.distance10Flat
        }
        else if (!layer.folded && layer.distance === "15") {
            productVariant = layer.product.distance15Flat
        }
        else if (layer.folded && layer.distance === "10") {
            productVariant = layer.product.distance10Folded
        }
        else if (layer.folded && layer.distance === "15") {
            productVariant = layer.product.distance15Folded
        }
    }
    return productVariant
}

function getTextileImage(layer) {
    if (layer.product && layer.product.images) {
      if (layer.colorIndex < 0) {
        return layer.product.imagesSmall[layer.product.images.length - 1]
      }
      else  {
        return layer.product.imagesSmall[layer.colorIndex]
      }
    }
    return false
  }

function noiseLevelReduction(absorptionAreaBefore, absorptionAreaAfter) {
    const dBReduction = Math.log10(absorptionAreaAfter / absorptionAreaBefore) * 10
    
    if (dBReduction >= 10) {
        return 10
    }
    return dBReduction
}

function dBReductionToGain(dBReduction) {
    return (Math.pow(10, -dBReduction / 20))
}


function reverberationFor(absorptionArea, roomVolume) {
    const reverberationTime = 0.16 * (roomVolume / absorptionArea)
    return reverberationTime
}

function getNumberString(nbr, nbrOfFractionDigits) {
    const {currentLocale} = intl.getInitOptions()
    nbr = nbr.toLocaleString(currentLocale, {
        minimumFractionDigits: nbrOfFractionDigits,
        maximumFractionDigits: nbrOfFractionDigits
    })
    return nbr
}


export default {atLeastOneProductActiveForRoom, getRoomName, surfaceAreaForRoom, absorptionAreaForEmptyRoom, absorptionAreaForLayer, absorptionAreaForTextiles, productVariantForLayer, getTextileImage, noiseLevelReduction, reverberationFor, dBReductionToGain, getNumberString}

