import React, {Component} from 'react'
import styles from './Switch.module.scss'

class Switch extends Component {

    render() {

        return (
            <div className={styles.container}>
                <label className={styles.switch}>
                    <span className={styles.label}>{this.props.label}</span>
                    <input 
                        type="checkbox" 
                        checked={this.props.checked}
                        onChange={this.props.onChange}
                    />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                </label>
                
            </div>
            
        )

    }

}

export default Switch