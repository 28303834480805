import imagesAkemi from '../img/akemi'
import imagesAkemiSmall from '../img/akemi_small'

import imagesBasal from '../img/basal'
import imagesBasalSmall from '../img/basal_small'

import imagesBlush from '../img/blush'
import imagesBlushSmall from '../img/blush_small'

import imagesDual from '../img/dual'
import imagesDualSmall from '../img/dual_small'

import imagesNoun from '../img/noun'
import imagesNounSmall from '../img/noun_small'

import imagesInteract from '../img/interact'
import imagesInteractSmall from '../img/interact_small'

import imagesGlory from '../img/glory'
import imagesGlorySmall from '../img/glory_small'

import imagesGrain from '../img/grain'
import imagesGrainSmall from '../img/grain_small'

import imagesOmbre from '../img/ombre'
import imagesOmbreSmall from '../img/ombre_small'

import imagesTune from '../img/tune'
import imagesTuneSmall from '../img/tune_small'

import imagesPrism from '../img/prism'
import imagesPrismSmall from '../img/prism_small'

import imagesSaint from '../img/saint'
import imagesSaintSmall from '../img/saint_small'

import imagesSense from '../img/sense'
import imagesSenseSmall from '../img/sense_small'

import imagesWell from '../img/well'
import imagesWellSmall from '../img/well_small'

import imagesYoyo from '../img/yoyo'
import imagesYoyoSmall from '../img/yoyo_small'


const products = [
    {
        "name": "Akemi",
        "images": imagesAkemi(),
        "imagesSmall": imagesAkemiSmall(),
        "rollWidth": "150",
        "distance10Flat": {
            "absorptionCoefficient": "0.50",
            "absorptionClass": "D",
            "shapeIndicator": "MH",
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.70",
            "absorptionClass": "C",
            "shapeIndicator": "H",
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.60",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.80",
            "absorptionClass": "B",
            "shapeIndicator": "H",
        },
        colors: [
            8700,
            6742,
            8102,
            3020,
            8100,
            6756,
            6732,
            6837,
            3655,
            3368,
            3355,
            3225,
            3978,
            3746,
            3935,
            3067,
            5868,
            5853,
            6426,
            5246,
            5235,
            4656,
            4466,
            4424,
            4377,
        ],
    },
    {
        "name": "Basal",
        "images": imagesBasal(),
        "imagesSmall": imagesBasalSmall(),
        "rollWidth": "300",
        "distance10Flat": {
            "absorptionCoefficient": "0.45",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.70",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.55",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.75",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        colors: [
            3011,
            3145,
            3872,
            4310,
            4420,
            4422,
            4471,
            4521,
            4542,
            4562,
            4651,
            5041,
            5530,
            6051,
            6070,
            6221,
            6360,
            6811,
            8000,
            8100,
        ],
    },
    {
        "name": "Blush",
        "images": imagesBlush(),
        "imagesSmall": imagesBlushSmall(),
        "rollWidth": "300",
        "distance10Flat": {
            "absorptionCoefficient": "0.55",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.80",
            "absorptionClass": "B",
            "shapeIndicator": null,
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.65",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.90",
            "absorptionClass": "A",
            "shapeIndicator": null,
        },
        colors: [
            3021,
            3235,
            3241,
            3872,
            4442,
            4471,
            4562,
            5431,
            5472,
            6232,
            6252,
            6311,
            6510,
            6531,
            6623,
            6700,
            6752,
            6943,
            8000,
            8220,
        ],
    },
    {
        "name": "Dual",
        "images": imagesDual(),
        "imagesSmall": imagesDualSmall(),
        "rollWidth": "300",
        "distance10Flat": {
            "absorptionCoefficient": "0.50",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.75",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.65",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.85",
            "absorptionClass": "B",
            "shapeIndicator": null,
        },
        colors: [
            3152,
            3340,
            3343,
            4431,
            4453,
            4651,
            5051,
            5350,
            6050,
            6530,
            6851,
            6910,
            8000,
            8300,
            8500,
        ],
    },
    {
        "name": "Glory",
        "images": imagesGlory(),
        "imagesSmall": imagesGlorySmall(),
        "rollWidth": "300",
        "distance10Flat": {
            "absorptionCoefficient": "0.50",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.80",
            "absorptionClass": "B",
            "shapeIndicator": null,
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.60",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.85",
            "absorptionClass": "B",
            "shapeIndicator": null,
        },
        colors: [
            4472,
            5831,
            6721,
            8000,
            8330,
        ],
    },
    {
        "name": "Grain",
        "images": imagesGrain(),
        "imagesSmall": imagesGrainSmall(),
        "rollWidth": "300",
        "distance10Flat": {
            "absorptionCoefficient": "0.40",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.60",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.40",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.60",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        colors: [
            8500,
            3560,
            6820,
            6930,
            6530,
            6780,
            6540,
            6521,
            6510,
            8000,
            3310,
            4421,
            4510,
            4021,
            4552,
            4645,
            4362,
            4533,
            4554,
            5040,
            4641,
            5060,
            6042,
            6235,
            6222,
            6450,
            6423,
            6635,
            3032,
            6630,
            3040,
            3136,
            3545,
        ],
    },
    {
        "name": "Interact",
        "images": imagesInteract(),
        "imagesSmall": imagesInteractSmall(),
        "rollWidth": "300",
        "distance10Flat": {
            "absorptionCoefficient": "0.50",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.75",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.60",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.75",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        colors: [
            1015,
            3221,
            4342,
            4415,
            4540,
            4622,
            5222,
            6511,
            6512,
            6530,
            6625,
            6720,
            6833,
            8101,
            8200
        ],
    },
    {
        "name": "Noun",
        "images": imagesNoun(),
        "imagesSmall": imagesNounSmall(),
        "rollWidth": "300",
        "distance10Flat": {
            "absorptionCoefficient": "0.45",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.65",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.50",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.75",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        colors: [
            3233,
            4431,
            4532,
            4560,
            4641,
            5071,
            5232,
            5750,
            6530,
            6732,
            6820,
            6841,
            8000,
            8200,
            8400
        ],
    },
    {
        "name": "Ombré",
        "images": imagesOmbre(),
        "imagesSmall": imagesOmbreSmall(),
        "rollWidth": "150",
        "distance10Flat": {
            "absorptionCoefficient": "0.60",
            "absorptionClass": "C",
            "shapeIndicator": "H",
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.60",
            "absorptionClass": "C",
            "shapeIndicator": "H",
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.60",
            "absorptionClass": "C",
            "shapeIndicator": "H",
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.60",
            "absorptionClass": "C",
            "shapeIndicator": "H",
        },
        colors: [
            8440,
            3010,
            6780,
            3154,
            4564,
            5922,
            6531,
            5310,
            4650,
            4280,
            4050,
            3132,
        ],
    },
    {
        "name": "Tune",
        "images": imagesTune(),
        "imagesSmall": imagesTuneSmall(),
        "rollWidth": "300",
        "distance10Flat": {
            "absorptionCoefficient": "0.55",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.75",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.70",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.90",
            "absorptionClass": "A",
            "shapeIndicator": null,
        },
        colors: [
            1500,
            3080,
            3310,
            4052,
            4336,
            4353,
            4374,
            4431,
            4510,
            4530,
            4871,
            5063,
            5211,
            6221,
            6370,
            6452,
            6510,
            6535,
            6620,
            6740
        ],
    },
    {
        "name": "Prism",
        "images": imagesPrism(),
        "imagesSmall": imagesPrismSmall(),
        "rollWidth": "300",
        "distance10Flat": {
            "absorptionCoefficient": "0.50",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.75",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.60",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.80",
            "absorptionClass": "B",
            "shapeIndicator": null,
        },
        colors: [
            4562,
            5023,
            6500,
            6623,
            8220,
        ],
    },
    {
        "name": "Saint",
        "images": imagesSaint(),
        "imagesSmall": imagesSaintSmall(),
        "rollWidth": "300",
        "distance10Flat": {
            "absorptionCoefficient": "0.50",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.75",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.60",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.85",
            "absorptionClass": "B",
            "shapeIndicator": null,
        },
        colors: [
            3121,
            4532,
            6022,
            6620,
            6943,
        ],
    },
    {
        "name": "Sense",
        "images": imagesSense(),
        "imagesSmall": imagesSenseSmall(),
        "rollWidth": "300",
        "distance10Flat": {
            "absorptionCoefficient": "0.55",
            "absorptionClass": "D",
            "shapeIndicator": "MH",
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.80",
            "absorptionClass": "B",
            "shapeIndicator": "H",
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.65",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.90",
            "absorptionClass": "A",
            "shapeIndicator": null,
        },
        colors: [
            3022,
            3522,
            3775,
            4374,
            4522,
            4644,
            6262,
            6460,
            6510,
            6530,
            6540,
            6634,
            6845,
            8100,
            8800,
        ],
    },
    {
        "name": "Well",
        "images": imagesWell(),
        "imagesSmall": imagesWellSmall(),
        "rollWidth": "150",
        "distance10Flat": {
            "absorptionCoefficient": "0.55",
            "absorptionClass": "D",
            "shapeIndicator": "MH",
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.80",
            "absorptionClass": "B",
            "shapeIndicator": "H",
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.65",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.85",
            "absorptionClass": "B",
            "shapeIndicator": "H",
        },
        colors: [
            8990,
            6980,
            8977,
            8300,
            8022,
            8010,
            1700,
            1500,
            3020,
            6730,
            6780,
            6880,
            6630,
            6967,
            3360,
            3655,
            3740,
            3680,
            3560,
            3950,
            4150,
            4540,
            4434,
            4656,
            4250,
            4980,
            4850,
            5120,
            5580,
            5860,
            6030,
        ],
    },
    {
        "name": "Yoyo",
        "images": imagesYoyo(),
        "imagesSmall": imagesYoyoSmall(),
        "rollWidth": "300",
        "distance10Flat": {
            "absorptionCoefficient": "0.55",
            "absorptionClass": "D",
            "shapeIndicator": null,
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.80",
            "absorptionClass": "B",
            "shapeIndicator": null,
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.70",
            "absorptionClass": "C",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.90",
            "absorptionClass": "A",
            "shapeIndicator": null,
        },
        colors: [
            3121,
            3206,
            3621,
            4422,
            5521,
            5913,
            6443,
            6611,
            6932,
            8000,
            8220,
            8440,
        ],
    },
    {
        "name": "REFERENCE",
        "images": null,
        "imagesSmall": null,
        "rollWidth": "150",
        "distance10Flat": {
            "absorptionCoefficient": "0.10",
            "absorptionClass": "0",
            "shapeIndicator": null,
        },
        "distance10Folded": {
            "absorptionCoefficient": "0.10",
            "absorptionClass": "0",
            "shapeIndicator": null,
        },
        "distance15Flat": {
            "absorptionCoefficient": "0.10",
            "absorptionClass": "0",
            "shapeIndicator": null,
        },
        "distance15Folded": {
            "absorptionCoefficient": "0.10",
            "absorptionClass": "0",
            "shapeIndicator": null,
        },
        colors: [
        ],
    },
]

export default products