import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './bootstrap.scss';
import './index.css';
import App from './App';
import { AuthProvider } from './shared/provider/AuthProvider ';

ReactDOM.render(
    <>
    <AuthProvider>
      <App />
    </AuthProvider>
    </>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
