import React, {Component} from 'react'
import intl from 'react-intl-universal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Session from './Session'
import styles from './Sessions.module.scss'
import MiddlewareService from '../../axios/service/MiddlewareService'

class Sessions extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showNewDialog: false,
            newName: '',
            sessions: null,
            currentExpandedSessionIndex: null,
            currentSessionIndex: 0
        }

        this.onNewSession = this.onNewSession.bind(this)
        this.onNewNameChange = this.onNewNameChange.bind(this)
        this.onCloseNewDialog = this.onCloseNewDialog.bind(this)
        this.onSaveNewSession = this.onSaveNewSession.bind(this)

        this.onSessionChange = this.onSessionChange.bind(this)
        this.onSessionDelete = this.onSessionDelete.bind(this)
        this.contractAllSessionsExcept = this.contractAllSessionsExcept.bind(this)
        this.onSessionActivate = this.onSessionActivate.bind(this)

    }

    onNewSession() {
        this.setState({
            showNewDialog: true
        })
    }

    onNewNameChange(e) {
        this.setState({
            newName: e.target.value
        })
    }

    onCloseNewDialog() {
        this.setState({
            showNewDialog: false,
            newName: ''
        })
    }

    onSaveNewSession() {
        const newSession = {
            "name": this.state.newName,
            "date": new Date(),
            "reports": [],
            "notes": null,
        }

        let sessions = []
        if (localStorage.getItem('sessions')) {
            sessions = JSON.parse(localStorage.getItem('sessions'))
        }
        sessions.push(newSession)
            localStorage.setItem('sessions', JSON.stringify(sessions))
        this.setState({
            sessions: sessions
        })

        

        this.onCloseNewDialog()    }

    onSessionChange(session, index) {
        let sessions = this.state.sessions
        sessions[index] = session
        this.setState({
            sessions: sessions
        })
        localStorage.setItem('sessions', JSON.stringify(sessions))
        
        if (index === this.state.currentSessionIndex) { 
            this.props.onCurrentSessionChanged(sessions[index])
        }

    }

    onSessionDelete(index) {

        let sessions = this.state.sessions
        sessions.splice(index, 1)
        this.setState({
            sessions: sessions
        })

        let currentSessionIndex = this.state.currentSessionIndex
        if (currentSessionIndex >= sessions.length) {
            currentSessionIndex -= 1
        }
        if (currentSessionIndex < 0) {
            currentSessionIndex = 0
        }
        this.setState({
            currentSessionIndex: currentSessionIndex
        })
        localStorage.setItem('currentSessionIndex', currentSessionIndex)
        localStorage.setItem('sessions', JSON.stringify(sessions))

        this.props.onCurrentSessionChanged(sessions[index])
    }

    contractAllSessionsExcept(index) {
        
        this.setState({
            currentExpandedSessionIndex: index
        })
    }

    onSessionActivate(index) { 
        if (index >= 0) {
            localStorage.setItem('currentSessionIndex', index)
            this.setState({
                currentSessionIndex: index
            })
            this.props.onCurrentSessionChanged(this.state.sessions[index])
        }
    }

    componentDidMount() {
        let currentSessionIndex = 0
        
        if (localStorage.getItem('currentSessionIndex')) {
            currentSessionIndex = localStorage.getItem('currentSessionIndex')
            this.setState({
                currentSessionIndex: currentSessionIndex
            })
        }
        
        if (localStorage.getItem('sessions')) {
            let sessions = JSON.parse(localStorage.getItem('sessions'))
            this.setState({
                sessions: sessions
            })
        }
        
    }

    render() {

        let sessionsContent = []
        let sessionNames = []
        let sessionIndexes = []
        
        if (this.state.sessions) {
            for (let i=0; i<this.state.sessions.length; i++) {
                sessionsContent.push(
                    <Session
                        key={i}
                        index={i}
                        data={this.state.sessions[i]}
                        onChange={this.onSessionChange}
                        onDelete={this.onSessionDelete}
                        onAccordionExpand={this.contractAllSessionsExcept}
                        shouldContract={this.state.currentExpandedSessionIndex === i ? false : true}
                        active={this.state.currentSessionIndex === i ? true : false}
                        onActivate={this.onSessionActivate}
                    />
                )
                sessionNames.push(this.state.sessions[i].name)
                sessionIndexes.push(i)
            }
        }

        return (
            <>
            <div className={styles.container}>
                <h1 className="text-center">{intl.get('sessions.sessions')}</h1>
                <div className="container">
                    <div className="row">
                        <div className="col-12 offset-sm-2 col-sm-8 text-center">
                            <Button variant="primary" className={`${styles.btn} ${styles.btnNewSession}`} onClick={this.onNewSession}>
                                {intl.get('sessions.new')}
                            </Button>
                        </div>
                        
                    </div>
                </div>
                {sessionsContent}
            </div>

            <Modal show={this.state.showNewDialog} onHide={this.onCloseNewDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>{intl.get('sessions.new')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>{intl.get('sessions.sessionName')}</Form.Label>
                    <Form.Control type="text" value={this.state.newName} onChange={this.onNewNameChange} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className={styles.btn} onClick={this.onCloseNewDialog}>
                        {intl.get('general.cancel')}
                    </Button>
                    <Button variant="primary" className={styles.btn} onClick={this.onSaveNewSession}>
                        {intl.get('general.add')}
                    </Button>
                </Modal.Footer>
            </Modal>

            </>
        )

    }

}

export default Sessions