import React, {Component} from 'react'
import Button from '../Lab/Button'
import intl from 'react-intl-universal'
import styles from './Start.module.scss'

class Start extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showBackgroundImage: false
        }

        
    }

    componentDidMount() {
        this.setupTimer = setTimeout(() => {
            this.setState({
                showBackgroundImage: true
            })
        }, 100)
    }

    handleLabStart() {
        let view = this.props.views.labStart
        if (!this.props.hasBeenSaved) {
            view = this.props.views.lab
        }
        this.props.onViewChanged(view)
    }

    componentWillUnmount() {
        clearTimeout(this.setupTimer)
    }

    render() {

        
        
        return (
            <div className={styles.container}>
                <div className={`${styles.imgContainer} ${this.state.showBackgroundImage ? styles.show : null}`}></div>
                <div className={styles.content}>
                    <h1>{intl.get('start.title')}</h1>
                        <Button rounded={true} strongBorders={true} label={intl.get('start.cta')} onClick={(e) => {this.handleLabStart()}} active={this.props.reportViewActive} />
                </div>
            </div>
        )
    }
}

export default Start
// export default translate('translations')(Start)