import React from 'react'

import styles from './lab.module.scss'

import Report from '../Reports/Report-2'
import Output from '../Output'
import Input from '../Input'
import LabNav from './LabNav'
import Button from './Button'
import iconClose from './icon-close.svg'

class Lab extends React.Component {

    constructor(props) {
        
        super(props)

        this.views = {
            lab: 'lab',
            report: 'report',
          }

        this.state = {
            currentView: this.views.report,
            inputpaneActive: false,
            fullWidthActive: false,
            fullWidthStartTime: 0,
            audioForRoomShouldPause: [],
        }

        this.toggleReportView = this.toggleReportView.bind(this)
        this.openInputpane = this.openInputpane.bind(this)
        this.closeInputpane = this.closeInputpane.bind(this)
        this.inputPaneWasOpen = false
        this.openFullWidth = this.openFullWidth.bind(this)
        this.closeFullWidth = this.closeFullWidth.bind(this)
        this.onActivateAbsorbantWall = this.onActivateAbsorbantWall.bind(this)
        this.atLeastOneProductActive = this.atLeastOneProductActive.bind(this)
        this.startedPlaying = this.startedPlaying.bind(this)
    }

    toggleReportView() {
        let view = this.props.labViews.report
        if (this.props.currentLabView === this.props.labViews.report) {
            view = this.props.labViews.lab
        }
        this.props.onLabViewChanged(view)
        console.log(view)
        // this.setState({
        //     currentView: view
        // })
    }

    openInputpane() {
        this.setState({
            inputpaneActive: true
        })
        this.props.fullWidthActive && this.closeFullWidth()
    }

    closeInputpane() {
        const storedUser = JSON.parse(sessionStorage.getItem("currentUser")); // Get user from sessionStorage
        const { salesAcoustic } = storedUser; // Gets salesAcoustic from storedUser
        if(salesAcoustic)
        {
        if (this.props.activeAbsorbantRoom.id === this.props.absorbantRooms.length - 1) {
            if (this.props.absorbantRooms.length >= 2) {
                    this.props.setLeftmostAbsorbantRoom(this.props.absorbantRooms[this.props.absorbantRooms.length - 2].id)
                }
            }
        }
        else
        {
            // If you are not salesAcoustic, return to index 0 after closing
            this.props.setLeftmostAbsorbantRoom(0)
        }
            this.setState({
            inputpaneActive: false
        })
    }

    openFullWidth(roomIndex) {
        this.props.setLeftmostAbsorbantRoom(roomIndex)
        this.setState({
            fullWidthActive: true,
            fullWidthStartTime: Date.now(),
        })
        if (this.state.inputpaneActive) {
            this.inputPaneWasOpen = true
        }

        this.setState({
            inputpaneActive: false
        })
    }
    closeFullWidth() {
        this.setState({
            fullWidthActive: false,
            fullWidthStartTime: Date.now(),
        })
        if (this.inputPaneWasOpen) {
            this.inputPaneWasOpen = false
            this.openInputpane()
        }
        else {
            if (this.props.activeAbsorbantRoom.id === this.props.absorbantRooms.length - 1) {
                if (this.props.absorbantRooms.length >= 2) {
                    this.props.setLeftmostAbsorbantRoom(this.props.absorbantRooms[this.props.absorbantRooms.length - 2].id)
                }
            }
        }
    }

    onActivateAbsorbantWall(roomId, wallIndex) {
        this.props.onActivateAbsorbantWall(roomId, wallIndex)
        this.openInputpane()
    }

    atLeastOneProductActive(index) {
        let atLeastOneProductActive = false
        this.props.absorbantRooms[index].absorbantWalls.forEach(wall => {
            if (
                (wall.layerOne.productIndex >= 0 && wall.layerOne.width > 0 && wall.layerOne.height > 0)
                || (wall.layerTwo.productIndex >= 0 && wall.layerTwo.width > 0 && wall.layerTwo.height > 0)
            ) {
                atLeastOneProductActive = true
            }
        })
        
        return atLeastOneProductActive
    }

    startedPlaying(roomId) {
        const audioForRoomShouldPause = []
        this.props.absorbantRooms.forEach((room) => {
            let shouldPause = true
            if (room.id === roomId) {
                shouldPause = false
            }
            audioForRoomShouldPause.push(shouldPause)
        })
        this.setState({
            audioForRoomShouldPause: audioForRoomShouldPause
        })
    }

    componentDidMount() {
        const audioForRoomShouldPause = []
        this.props.absorbantRooms.forEach((room) => {
            audioForRoomShouldPause.push(false)
        })
        this.setState({
            audioForRoomShouldPause: audioForRoomShouldPause
        })
    }
    

    componentDidUpdate() {
        
    }

    render() {  
        let outputMarginLeft = -this.props.leftmostAbsorbantRoomIndex * 50
        this.state.fullWidthActive && (outputMarginLeft *= 2)

        return (
            <div className={styles.container}>

                <LabNav
                    views={this.props.views}
                    hasBeenSaved={this.props.hasBeenSaved}
                    onViewChanged={this.props.onViewChanged}
                    resetToDefaults={this.props.resetToDefaults}
                    absorbantRooms = {this.props.absorbantRooms}
                    activeAbsorbantRoomIndex = {this.props.activeAbsorbantRoom.id}
                    atLeastOneProductActive = {this.atLeastOneProductActive}
                    leftmostAbsorbantRoomIndex = {this.props.leftmostAbsorbantRoomIndex}
                    setLeftmostAbsorbantRoom = {this.props.setLeftmostAbsorbantRoom}
                    inputpaneActive = {this.state.inputpaneActive}
                    fullWidthActive = {this.state.fullWidthActive}
                    toggleReportView = {this.toggleReportView}
                    // reportViewActive = {this.state.currentView === this.views.report}
                    currentLabView={this.props.currentLabView}
                    labViews={this.props.labViews}
                    onLabViewChanged={this.props.onLabViewChanged}
                    roomWidth={this.props.roomWidth}
                    roomHeight={this.props.roomHeight}
                    roomDepth={this.props.roomDepth}
                    ceilingMaterial={this.props.ceilingMaterial}
                    wallMaterial={this.props.wallMaterial}
                    floorMaterial={this.props.floorMaterial}
                ></LabNav>



                {this.props.currentLabView === this.props.labViews.lab &&

                <>

                    <div className={`${styles.btnFullWidthClose} ${!this.state.fullWidthActive && styles.hidden}`}>
                        <Button icon={<img src={iconClose} alt="icon"/>} circular={true} onClick={() => {this.closeFullWidth()}} />
                    </div>

                    <div className={styles.outputsContainer}>

                        <Output      
                            onRoomNameChanged={this.props.onRoomNameChanged}
                            roomWidth={this.props.roomWidth}
                            roomDepth={this.props.roomDepth}
                            roomHeight={this.props.roomHeight}
                            ceilingMaterial = {this.props.ceilingMaterial}
                            wallMaterial = {this.props.wallMaterial}
                            floorMaterial = {this.props.floorMaterial}
                            absorbantRooms = {this.props.absorbantRooms}
                            absorbantRoom = {this.props.absorbantRooms[0]}
                            atLeastOneProductActive = {this.atLeastOneProductActive}
                            activeAbsorbantRoomIndex = {this.props.activeAbsorbantRoom.id}
                            onActivateAbsorbantWall = {this.onActivateAbsorbantWall}
                            inputpaneActive = {this.state.inputpaneActive}
                            fullWidthActive = {this.state.fullWidthActive}
                            fullWidthStartTime = {this.state.fullWidthStartTime}
                            openFullWidth = {this.openFullWidth}
                            marginLeft = {outputMarginLeft+'%'}
                            startedPlaying = {this.startedPlaying}
                            shouldPause = {this.state.audioForRoomShouldPause[0]}
                        />

                        <Output             
                            onRoomNameChanged={this.props.onRoomNameChanged}
                            roomWidth={this.props.roomWidth}
                            roomDepth={this.props.roomDepth}
                            roomHeight={this.props.roomHeight}
                            ceilingMaterial = {this.props.ceilingMaterial}
                            wallMaterial = {this.props.wallMaterial}
                            floorMaterial = {this.props.floorMaterial}
                            absorbantRooms = {this.props.absorbantRooms}
                            absorbantRoom = {this.props.absorbantRooms[1]}
                            atLeastOneProductActive = {this.atLeastOneProductActive}
                            activeAbsorbantRoomIndex = {this.props.activeAbsorbantRoom.id}
                            onActivateAbsorbantWall = {this.onActivateAbsorbantWall}
                            inputpaneActive = {this.state.inputpaneActive}
                            fullWidthActive = {this.state.fullWidthActive}
                            fullWidthStartTime = {this.state.fullWidthStartTime}
                            openFullWidth = {this.openFullWidth}
                            marginLeft = {outputMarginLeft+'%'}
                            startedPlaying = {this.startedPlaying}
                            shouldPause = {this.state.audioForRoomShouldPause[1]}
                        />

                        <Output             
                            onRoomNameChanged={this.props.onRoomNameChanged}
                            roomWidth={this.props.roomWidth}
                            roomDepth={this.props.roomDepth}
                            roomHeight={this.props.roomHeight}
                            ceilingMaterial = {this.props.ceilingMaterial}
                            wallMaterial = {this.props.wallMaterial}
                            floorMaterial = {this.props.floorMaterial}
                            absorbantRooms = {this.props.absorbantRooms}
                            absorbantRoom = {this.props.absorbantRooms[2]}
                            atLeastOneProductActive = {this.atLeastOneProductActive}
                            activeAbsorbantRoomIndex = {this.props.activeAbsorbantRoom.id}
                            onActivateAbsorbantWall = {this.onActivateAbsorbantWall}
                            inputpaneActive = {this.state.inputpaneActive}
                            fullWidthActive = {this.state.fullWidthActive}
                            fullWidthStartTime = {this.state.fullWidthStartTime}
                            openFullWidth = {this.openFullWidth}
                            marginLeft = {outputMarginLeft+'%'}
                            startedPlaying = {this.startedPlaying}
                            shouldPause = {this.state.audioForRoomShouldPause[2]}
                        />
                        
                    </div>

                    <Input 
                        onCurrentSessionChanged={this.props.onCurrentSessionChanged}
                        readyToSave={this.props.readyToSave}
                        mode={this.props.projectInputMode}
                        onModeChange={this.props.setProjectInputMode}
                        roomWidth={this.props.roomWidth}
                        onRoomWidthChanged={this.props.onRoomWidthChanged}
                        roomDepth={this.props.roomDepth}
                        onRoomDepthChanged={this.props.onRoomDepthChanged}
                        roomHeight={this.props.roomHeight}
                        onRoomHeightChanged={this.props.onRoomHeightChanged}
                        ceilingMaterialIndex = {this.props.ceilingMaterialIndex}
                        onCeilingMaterialChanged = {this.props.onCeilingMaterialChanged}
                        wallMaterialIndex = {this.props.wallMaterialIndex}
                        onWallMaterialChanged = {this.props.onWallMaterialChanged}
                        floorMaterialIndex = {this.props.floorMaterialIndex}
                        onFloorMaterialChanged = {this.props.onFloorMaterialChanged}
                        absorbantRoom = {this.props.activeAbsorbantRoom}
                        absorbantRooms = {this.props.absorbantRooms}
                        onActivateAbsorbantWall = {this.props.onActivateAbsorbantWall}
                        onAbsorbantRoomChanged = {this.props.onAbsorbantRoomChanged}
                        active = {this.state.inputpaneActive}
                        open = {this.openInputpane}
                        close = {this.closeInputpane}
                    />
                </>
                }



                {this.props.currentLabView === this.props.labViews.report &&
                    
                    <Report
                        roomWidth={this.props.roomWidth}
                        roomHeight={this.props.roomHeight}
                        roomDepth={this.props.roomDepth}
                        ceilingMaterial={this.props.ceilingMaterial}
                        wallMaterial={this.props.wallMaterial}
                        floorMaterial={this.props.floorMaterial}
                        absorbantRooms={this.props.absorbantRooms}
                        onChange={this.onReportChange}
                        onDelete={this.onReportDelete}
                        startedPlaying = {this.startedPlaying}
                        shouldPause = {this.state.audioForRoomShouldPause}
                        atLeastOneProductActive = {this.atLeastOneProductActive}
                    />
                }


                
                
            </div>
        )
    }

}

export default Lab