import MiddlewareService from '../../axios/service/MiddlewareService';

const verifyLogin = async (updateAuthContext) => {
    try {
     // Gets user and token from sessionstorage if the exsits and converts them to Json object, if sessionstorage i emty there will be a check for urlToken
      const storedUser = JSON.parse(sessionStorage.getItem("currentUser"));
      const storedToken = JSON.parse(sessionStorage.getItem("currentToken"));

     if (storedUser && storedToken) {
          updateAuthContext(storedUser, storedToken) // Calls updateAuthContext so that isLoading will be set to false
         return;
     }
     else
     {
         // Fetch URL:en from browser
         const url = new URL(window.location.href);
     
         // Get token from URL
         const urlToken = url.searchParams.get("token");
     
         // Check if token exists otherwise redirect to SVEN-login
         if (!urlToken) {
           window.location.href = process.env.REACT_APP_API_BASE_URL_SVEN; 
           throw new Error("urlToken is missing");
         }
     
         // Send post request with the secretToken
         const response = await MiddlewareService.createSignInSecretToken({ secretToken: urlToken }); // Use middlewareService to use createSignInProtectedLink 
         const currentUser = response.currentUser;
         const currentToken = response.token;
         
         updateAuthContext(currentUser, currentToken);
         
         // Save user in sessionStorage
         sessionStorage.setItem("currentUser", JSON.stringify(currentUser)); // You can only store string-format in sessionstorage therefor you have to convert the json-object to string 
         sessionStorage.setItem("currentToken", JSON.stringify(currentToken)); 
        
         if (response.currentUser != null) {
          MiddlewareService.getSessionsByEmail(currentUser.email)
            .then((sessions) => {
              if(sessions.length > 0)
              {
                localStorage.setItem('sessions', JSON.stringify(sessions));
              }
              else 
              {
                console.log("No saved sessions")
              }
        
            })
            .catch((error) => {
              console.error("error when fetching sessions", error);
            });
        }

         // Restore original URL without the token
         url.searchParams.delete("token");
         window.history.replaceState(null, null, url.toString());
         return response.data;
     }
    } catch (error) {
      console.error("Error in verifyLogin:", error);
      // Redirect to Sven login page if token is missing or invalid
      window.location.href = window.location.href = process.env.REACT_APP_API_BASE_URL_SVEN;
    }
  };
  
  export default verifyLogin;