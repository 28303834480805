class AbsorbantWall {
    
    constructor(name, description) {
        this.name = name
        this.description = description
        this.layerOne = new AbsorbantLayer()
        this.layerTwo = new AbsorbantLayer()
    }
   
}

export default AbsorbantWall

class AbsorbantLayer {
    
    constructor() {
        this.productIndex = -1
        this.width = 0
        this.height = 0
        this.folded = true
        this.distance = 15
        this.product = null
        this.colorIndex = 0
        this.readyToSave = false
    }

}


