import React, { createContext, useState, useEffect } from 'react';
import verifyLogin from '../../components/LogIn/verifyLogin'; 
import Backdrop from '../../components/LogIn/loadingBackdrop';

// AuthContext is created and AuthProvider wraps everything up in App.js so that authenticatedUser can be reached globally in the project.
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // State to indicate if authentication verification is in progress

  useEffect(() => {
    verifyLogin(updateAuthContext); // VerifyLogin is called one time when page rendes the first time
  }, []); 

  useEffect(() => {
    const checkLoggedInInterval = setInterval(() => {
      const storedUser = sessionStorage.getItem("currentUser");
      const storedToken = sessionStorage.getItem("currentToken");
      if (storedUser && storedToken) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        clearInterval(checkLoggedInInterval);
        // Add navigation to login? 
      }
    }, 60000); // Check every 60 sek
  
    return () => {
      clearInterval(checkLoggedInInterval);
    };
  }, []);
  
  const updateAuthContext = (user, token) => {
    setCurrentUser(user);
    setToken(token);
    setIsLoading(false); // Once authentication is done, set loading to false
  };

  if (isLoading) {
    return  <Backdrop/>;
  }
  
  // To make the provider work on wrapped elements "children" are added. 
  return (
    <AuthContext.Provider value={{ currentUser, token, updateAuthContext }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };