import React, {Component} from 'react'
import intl from 'react-intl-universal'

import styles from './Input.module.scss'
import RoomVolume from './RoomVolume'
import RoomSurfaces from './RoomSurfaces'
import RoomTextiles from './RoomTextiles/'
import Button from '../Lab/Button'

import iconClose from '../Lab/icon-close.svg'

class Input extends Component {

    constructor(props) {
        super(props)
        
        this.onSaveReport = this.onSaveReport.bind(this)
        
        this.state = {
            scrollEnabled: true
        }

        this.disableScroll = this.disableScroll.bind(this)
        this.enableScroll = this.enableScroll.bind(this)
    }

    onSaveReport(reportName) {

        let currentSessionIndex = 0
        if (localStorage.getItem('currentSessionIndex')) {
            currentSessionIndex = localStorage.getItem('currentSessionIndex')
        }
        

        let sessions = []
        if (localStorage.getItem('sessions')) {
            sessions = JSON.parse(localStorage.getItem('sessions'))
        }
        if (sessions.length === 0) {
            sessions[0] = {
                'name': 'Session',
                'date': new Date(),
                'reports': [],
            }
        }
        
        let reports = []
        if (sessions[currentSessionIndex].reports) {
            reports = sessions[currentSessionIndex].reports
        }
        
        let report = {
            'reportName': reportName,
            'roomWidth': this.props.roomWidth,
            'roomDepth': this.props.roomDepth,
            'roomHeight': this.props.roomHeight,
            'ceilingMaterialIndex': this.props.ceilingMaterialIndex,
            'wallMaterialIndex': this.props.wallMaterialIndex,
            'floorMaterialIndex': this.props.floorMaterialIndex,
            'absorbantWalls': this.props.absorbantRoom.absorbantWalls,
            'absorbantRooms': this.props.absorbantRooms,
        }
        sessions[currentSessionIndex].reports.push(report)

        localStorage.setItem('sessions', JSON.stringify(sessions))

        this.props.onCurrentSessionChanged(sessions[currentSessionIndex])

    }

    disableScroll() {
        this.setState({
            scrollEnabled: false
        })
    }

    enableScroll() {
        this.setState({
            scrollEnabled: true
        })
    }





    render() {
        
        return (
            <div
                className={`
                    ${styles.inputPane} 
                    ${!this.state.scrollEnabled && styles.noScroll} 
                    ${this.props.active && styles.active}
                `}>


                <div 
                    className={styles.btnCloseTop}>
                   
                    <Button 
                        square={true} 
                        borders={false} 
                        icon={<img src={iconClose} alt="icon" />} 
                        onClick={this.props.close}
                    />
                </div>
                
                {this.props.absorbantRoom.empty ?
                    <>
                        <RoomVolume 
                            roomWidth={this.props.roomWidth}
                            onRoomWidthChanged={this.props.onRoomWidthChanged}
                            roomDepth={this.props.roomDepth}
                            onRoomDepthChanged={this.props.onRoomDepthChanged}
                            roomHeight={this.props.roomHeight}
                            onRoomHeightChanged={this.props.onRoomHeightChanged}
                            disableScroll={this.disableScroll}
                            enableScroll={this.enableScroll}
                        />
                        <RoomSurfaces
                            ceilingMaterialIndex = {this.props.ceilingMaterialIndex}
                            onCeilingMaterialChanged = {this.props.onCeilingMaterialChanged}
                            wallMaterialIndex = {this.props.wallMaterialIndex}
                            onWallMaterialChanged = {this.props.onWallMaterialChanged}
                            floorMaterialIndex = {this.props.floorMaterialIndex}
                            onFloorMaterialChanged = {this.props.onFloorMaterialChanged}
                        />
                    </>

                    : 

                    <RoomTextiles
                        roomWidth = {this.props.roomWidth}
                        roomDepth = {this.props.roomDepth}
                        roomHeight = {this.props.roomHeight}
                        absorbantRoom = {this.props.absorbantRoom}
                        onActivateAbsorbantWall = {this.props.onActivateAbsorbantWall}
                        onAbsorbantRoomChanged = {this.props.onAbsorbantRoomChanged}
                        disableScroll = {this.disableScroll}
                        enableScroll = {this.enableScroll}
                    />
                }
                
                <div className={styles.btnCloseBottom}>
                    <Button 
                        rounded={true} 
                        strongBorders={true} 
                        label={intl.get('general.close')} 
                        onClick={this.props.close}
                    />
                </div>

                <div className={styles.bottomSpace}></div>
                
                {this.props.readyToSave ?
                <>
                {/* <BottomNav
                    onChange={this.onSaveReport}
                /> */}
                </>
                : null}

            </div>
        )
    }
}

export default Input

