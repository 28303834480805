import React, {Component} from 'react'
import intl from 'react-intl-universal'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import styles from './Session.module.scss'
import editIcon from './icons8-pencil-52.png'

class Session extends Component {

    constructor(props) {
        super(props)

        this.state = {
            expanded: false,
            showEditName: false,
            currentNameInputValue: this.props.data.reportName,
            showDelete: false,
        }

        this.accordion = this.accordion.bind(this)

        this.onOpenNameChange = this.onOpenNameChange.bind(this)
        this.onNameInputChange = this.onNameInputChange.bind(this)
        this.onNameSave = this.onNameSave.bind(this)
        this.onNameChangeClose = this.onNameChangeClose.bind(this)

        this.onOpenDelete = this.onOpenDelete.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onDeleteClose = this.onDeleteClose.bind(this)

        this.onNotesChange = this.onNotesChange.bind(this)
        this.onActivate = this.onActivate.bind(this)
        
    }

    accordion(e) {
        if (this.panel.style.maxHeight) {
            this.panel.style.maxHeight = null
            this.accExpand.innerHTML = '+'
            this.setState({
                expanded: false
            })
        }
        else {
            this.panel.style.maxHeight = this.panel.scrollHeight + 'px'
            this.accExpand.innerHTML = '-'
            this.setState({
                expanded: true
            })
            this.props.onAccordionExpand(this.props.index)
        }
    }

    // onNameChange(e) {
    //     let data = this.props.data
    //     data.name = e.target.value
    //     this.props.onChange(data, this.props.key)
    // }

    onOpenNameChange() {
        this.setState({
            showChangeName: true
        })
    }

    onNameInputChange(e) {
        this.setState({
            currentNameInputValue: e.target.value
        })
    }

    onNameSave() {
        // let data = this.props.data
        // data.reportName = this.state.currentNameInputValue
        // this.props.onChange(data, this.props.index)

        let data = this.props.data
        data.name = this.state.currentNameInputValue
        this.props.onChange(data, this.props.index)

        this.onNameChangeClose()
    }

    onNameChangeClose() {
        this.setState({
            showChangeName: false
        })
    }

    onOpenDelete() {
        this.setState({
            showDelete: true
        })
    }

    onDelete() {
        this.onDeleteClose()
        this.accordion()
        this.props.onDelete(this.props.index)
    }

    onDeleteClose() {
        this.setState({
            showDelete: false
        })
    }

    onNotesChange(e) {
        let data = this.props.data
        data.notes = e.target.value
        this.props.onChange(data, this.props.index)
    }

    componentDidUpdate() {
        if (this.state.expanded && this.props.shouldContract) {
            this.accordion()
        }
    }

    onActivate() {
        
        this.props.onActivate(this.props.index)
    }

    render() {

        

        return (

            <>

                {/* <div className={styles.container}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 offset-sm-2 col-sm-8">
                                <Form.Group>
                                <Form.Label>Namn på session</Form.Label>
                                <Form.Control type="text" value={this.props.data.name} onChange={this.onNameChange} />
                                </Form.Group>
                                <Form.Group>
                                <Form.Label>Anteckningar</Form.Label>
                                <Form.Control as="textarea" rows="10" value={this.props.data.notes ? this.props.data.notes : ''} onChange={this.onNotesChange} />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    
                </div> */}

                <div className={`${styles.container} ${this.props.active ? styles.active : null}`}>

                    <div className="container">
                
                        <div className={`row ${styles.accordion}`} onClick={this.accordion}>
                        
                            <div className="col-1">
                            </div>
                            <div className="col-9">
                                <span className={`${styles.accTitle}`}>{this.props.data.name}</span>
                            </div>
                            <div className="col-2 text-right">
                                <span className={styles.accExpand} ref={ref => (this.accExpand = ref)}>+</span>
                            </div>

                        </div>

                        <div className={`row ${styles.panel}`} ref={ref => (this.panel = ref)}>

                             <div className={`col-12 offset-md-3 col-md-6 text-center ${styles.sectionHeader}`}>
                                <h3 className={styles.edit}>{this.props.data.name}<img className={styles.editIcon} src={editIcon} onClick={this.onOpenNameChange} width="20" height="20" alt="Ändra namn"></img></h3>
                            
                                <Button className={`${styles.btn} ${styles.erase}`} onClick={this.onOpenDelete}>
                                    {intl.get('session.delete')}
                                </Button>
                                <Button className={`${styles.btn} ${styles.activate}`} onClick={this.onActivate}>
                                    {intl.get('session.activate')}
                                </Button>
                            
                            </div>
                            
                            <div className={`col-12 offset-md-3 col-md-6 ${styles.sectionHeader}`}>
                                {/* <Form.Group>
                                <Form.Label>Namn på session</Form.Label>
                                <Form.Control type="text" value={this.props.data.name} onChange={this.onNameChange} />
                                </Form.Group> */}
                                <Form.Group>
                                <Form.Label>{intl.get('session.notes')}</Form.Label>
                                <Form.Control as="textarea" rows="10" value={this.props.data.notes ? this.props.data.notes : ''} onChange={this.onNotesChange} />
                                </Form.Group>
                            </div>

                        </div>

                    </div>

                </div>

                <Modal show={this.state.showChangeName} onHide={this.onNameChangeClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{intl.get('session.editName')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>{intl.get('session.name')}</Form.Label>
                        <Form.Control type="text" defaultValue={this.props.data.name} onChange={this.onNameInputChange} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className={styles.btn} onClick={this.onNameChangeClose}>
                            {intl.get('general.cancel')}
                        </Button>
                        <Button variant="primary" className={styles.btn} onClick={this.onNameSave}>
                            {intl.get('general.ok')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showDelete} onHide={this.onDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{intl.get('session.delete')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>{intl.get('session.deleteConfirmation')}</Form.Label>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className={styles.btn} onClick={this.onDeleteClose}>
                        {intl.get('general.cancel')}
                    </Button>
                    <Button variant="secondary" className={styles.btn} onClick={this.onDelete}>
                        {intl.get('general.delete')}
                    </Button>
                </Modal.Footer>
            </Modal>

            </>

        )

    }


}

export default Session