import React, {Component} from 'react'
import styles from './HorizontalBar.module.scss'

class HorizontalBar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            didMount: false
        }

    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                didMount: true
            })
        }, 200)
    }

    

    render() {
        
        let barWidth = null
        this.state.didMount ? barWidth = this.props.percent : barWidth = 0

        
        

        return (
            <div className={`
                ${styles.container} 
                ${this.state.didMount ? styles.mounted : null} 
                ${this.props.highlight === "left" && styles.highlightLeft}
                ${this.props.labelPosition === "top" && styles.labelTop}
                `}>
           

                    <div className={styles.barContainer}>
                        <div className={styles.barBottom}></div>
                        <div className={styles.bar} style={{width: barWidth+"%"}}></div>
                    </div>
                    <div className={styles.labelContainer}>
                        {this.props.labelLeft && 
                            <div className={`${styles.label} ${styles.labelLeft}`}>{this.props.labelLeft}</div>
                        }
                        {this.props.labelRight &&
                            <div className={`${styles.label} ${styles.labelRight}`}>{this.props.labelRight}</div>
                        }
                    </div>
                
                </div>

        )

    }

}

export default HorizontalBar