import React, {Component} from 'react'
import styles from './Radiobutton.module.scss'

class Radiobutton extends Component {

    render() {

        return (
            <div className={`
                ${styles.container}
                ${this.props.fullContainerHeight && styles.fullHeight}
            `}>

                <label className={`
                    ${styles.label}
                `}>
                    <input 
                        name={this.props.name}
                        type="radio"
                        value={this.props.value}
                        checked={this.props.checked}
                        onChange={this.props.onChange}
                    />
                    <span className={styles.checkmark}></span>
                    <span className={`
                        ${styles.labelText}
                        ${this.props.hideLabel && styles.hidden}
                    `}>
                        {this.props.label}
                    </span>
                </label>

            </div>
            
        )

    }

}

export default Radiobutton