import BaseUrl from "../BaseUrl";

const createSignInSecretToken = async (secretToken) => {
  try {
    const response = await BaseUrl.post("/api/app/account/signinsecrettoken", secretToken );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getSessionsByEmail = async (email) => {
  try {
    const response = await BaseUrl.get(`/api/app/acousticsession/sessionsbyemail?userEmail=${email}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};


const createSession = async (sessions) => {
  try {
    console.log(sessions);
    const response = await BaseUrl.post("/api/app/acousticsession/session", sessions)
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const deleteSession = async (email, reportId) => {
  try {
    console.log(email, reportId)
    const response = await BaseUrl.delete(`/api/app/acousticsession/session/${reportId}?userEmail=${email}`)
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

  
export default { createSignInSecretToken, createSession, getSessionsByEmail, deleteSession };
