import React, {Component} from 'react'
import intl from 'react-intl-universal'
import Button from './Button'
import AbsorbantRoom from '../../utils/AbsorbantRoom'
import { AbsorbantLayer } from '../../utils/AbsorbantRoom'
import products from '../../data/products'
import helpers from '../../utils/helpers'
import styles from './LabStart.module.scss'

class LabStart extends Component {

    constructor(props) {
        super(props)

        this.state = {
            didMount: false,
        }
       
        this.defaults = this.defaults.bind(this)
        this.updateLocalStorageCache = this.updateLocalStorageCache.bind(this)

        this.templates = [
            {
            'name': 'lab.start.conference',
            'roomWidth': 4,
            'roomDepth': 8,
            'roomHeight': 3,
            'ceilingMaterialIndex': 0,
            'wallMaterialIndex': 0,
            'floorMaterialIndex': 0,
            'absorbantRooms': undefined
            },
            {
            'name': 'lab.start.office',
            'roomWidth': 2.5,
            'roomDepth': 3,
            'roomHeight': 3,
            'ceilingMaterialIndex': 0,
            'wallMaterialIndex': 0,
            'floorMaterialIndex': 0,
            'absorbantRooms': undefined
            },
            {
            'name': 'lab.start.classRoom',
            'roomWidth': 24,
            'roomDepth': 12,
            'roomHeight': 3,
            'ceilingMaterialIndex': 0,
            'wallMaterialIndex': 0,
            'floorMaterialIndex': 0,
            'absorbantRooms': undefined
            }
        ]

        this.templates.forEach((template, index) => {
            template.absorbantRooms = [
                new AbsorbantRoom(0),
                new AbsorbantRoom(1, false),
                new AbsorbantRoom(2, false)
            ]

            let productIndex = 0
            switch(index) {
                case 0:
                    productIndex = 1
                    break
                case 1: 
                    productIndex = 1
                    break
                case 2:   
                    productIndex = 1
                    break
                default:
                    productIndex = 0
            }

            const layerX = new AbsorbantLayer(productIndex, template.roomWidth, template.roomHeight, true, 15, products[productIndex], 0, false)
            const layerZ = new AbsorbantLayer(productIndex, template.roomDepth, template.roomHeight, true, 15, products[productIndex], 0, false)

            template.absorbantRooms[1].absorbantWalls[0].layerOne = layerX
            template.absorbantRooms[1].absorbantWalls[1].layerOne = layerZ
            template.absorbantRooms[1].absorbantWalls[2].layerOne = layerX
            template.absorbantRooms[1].absorbantWalls[3].layerOne = layerZ
            
        })
        
    }

    defaults() {
        this.props.resetToDefaults()
        this.props.setLeftmostAbsorbantRoom(0)
        this.props.onViewChanged(this.props.views.lab)
        this.props.setHasBeenSaved(false)
    }

    updateLocalStorageCache(event, index) {
        
        try {
            const currentCache = this.templates[index]
            localStorage.setItem('currentCache', JSON.stringify(currentCache))
            this.props.restoreFromCache()
            this.props.setLeftmostAbsorbantRoom(0)
            this.props.onViewChanged(this.props.views.lab)
        } catch(error) {console.log(error)
            this.props.resetToDefaults()
        }
        this.props.setHasBeenSaved(false)
        
    }
   

    componentDidMount() {
        this.setState({
            didMount: true
        })
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
       
        const templateEls = []
        if (this.templates) {
            this.templates.forEach((template, index) => {
                templateEls.push(
                    <li key={"template_"+index}>
                        <Button 
                            linkStyle={true} 
                            rounded={false} 
                            label={`${intl.get(template.name)} ${helpers.getNumberString(template.roomWidth)} x ${template.roomDepth} m`} 
                            onClick={(e) => {this.updateLocalStorageCache(e, index)}} 
                            active={this.props.reportViewActive} />
                    </li>
                )
            })
        }
      
        return (

            <div className={styles.container}>
                
                <h2>{intl.get('lab.start.header')}</h2>
           
                <p>{intl.get('lab.start.description')}</p>
                
                <div className={styles.btnDefault}>
                    <Button rounded={true} strongBorders={true} label={intl.get('lab.start.btnDefaults')} onClick={this.defaults} active={this.props.reportViewActive} />
                </div>
                 
                <p>{intl.get('lab.start.templateSummary')}</p>

            </div>

        )

    }

}

export default LabStart